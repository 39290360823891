import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>温度を測る。</h2>
					<div className="content-container">
						<div className="office_inner">
							<div className="flex-container">
								<div className="temp04_flex2 temp04_margin">
									<ul className="temp03_ul_flex">
										<li className="temp04_flex2 temp04_margin">
											<p className="medium">
												私たちは、体温を測ることにより、身体の健康状態を知ることができます。また、気温を測ることにより、日常生活に役立てています。
											</p>

											<p className="medium">
												このほか、今日では、さまざまな分野で高度な温度測定が行われ、有効活用されています。
											</p>

											<p className="medium">
												ことに、自然科学、各種産業における研究・開発、生産管理などの分野では、温度をひとつの情報として活用することが積極的に行われています。
											</p>

											<p className="medium">
												なかでも非接触型温度計は、温度測定の領域を飛躍的に広げることのできる温度計として注目されており、すでに学術・産業の分野で幅広く活躍しています。
											</p>

											<h2 className="temp02_font_title medium">
												触れて温度を測る接触型温度計。
											</h2>

											<p className="medium">
												私たちにとってなじみの深い体温計は、測りはじめてから少なくとも数分の時間が必要です。
											</p>

											<p className="medium">
												その理由は、体温計のなかの水銀が体温と同じ温度になるのを待たなければならないからです。
											</p>

											<p className="medium">
												これは、
												<a
													href="/support/documents/about-temperature/temp10#thermal_equilibrium"
													class="temp01_absolutetemperature"
												>
													熱平衡*
												</a>
												と呼ばれ、接触型の温度計はすべて、この原理を利用しています。
											</p>

											<p className="medium">
												この接触型温度計は測定物体に直接触れなければならないことと、熱平衡に時間が必要なため、測定の条件、対象物が限定されることがあります。
											</p>
											<a
												href="/support/documents/about-temperature/temp10#thermal_equilibrium"
												class="temp01_absolutetemperature"
											>
												*熱平衡（温度計一口メモ）
											</a>
										</li>
									</ul>
								</div>

								<div className="temp03_infrared temp04_margin2">
									<img
										className="temp04_image"
										src="/asset/Illust05.jpg"
										width="150"
										hight="150"
										alt="遠くにあるものの温度測定動いてるものの温度測定"
									/>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp04_flex2 temp04_margin">
									<ul className="temp03_ul_flex">
										<li className="temp04_flex2 temp04_margin">
											<p className="medium temp02_history_name">体温計</p>
											<p className="medium">
												体温計には、水銀を密閉したもののほか、エレクトロニクス技術を利用したデジタル体温計も、開発されています。
												家庭や病院に常備され健康状態を知る上で大切な役割を果たしています。
											</p>
										</li>

										<li className="temp03_flex1">
											<img
												className="temp04_image2"
												src="/asset/p01.jpg"
												width="100"
												hight="100"
												alt="体温計"
											/>
										</li>
									</ul>
									<ul className="temp03_ul_flex">
										<li className="temp04_flex2 temp04_margin">
											<p className="medium temp02_history_name">寒暖計</p>
											<p className="medium">
												接触型としては最もポピュラーな温度計。気温の高低を測る装置として、一般家庭のほか学校、オフィスなどで幅広く使用されています。
												また、農業関係ではハウス栽培などの室温計として活躍するなど生産の分野でも大切な役割を果たしています。
											</p>
										</li>

										<li className="temp03_flex1">
											<img
												className="temp04_image2"
												src="/asset/p02.jpg"
												width="100"
												hight="100"
												alt="寒暖計"
											/>
										</li>
									</ul>

									<ul className="temp03_ul_flex">
										<li className="temp04_flex2 temp04_margin">
											<p className="medium temp02_history_name">熱電対</p>
											<p className="medium">
												ほとんどの製造工場で使用されている温度計。
												接触型のため測定物体の温度に影響を与え、測定に時間がかかるデメリットもあります。
											</p>
										</li>

										<li className="temp03_flex1">
											<img
												className="temp04_image2"
												src="/asset/p03.jpg"
												width="100"
												hight="100"
												alt="熱電対"
											/>
										</li>
									</ul>
								</div>

								<div className="temp03_infrared temp04_margin2">
									<img
										className="temp04_image3"
										src="/asset/8200.jpg"
										width="150"
										hight="150"
										alt="非接触型温度計"
									/>

									<ul className="temp03_ul_flex text_align_left">
										<li className="temp04_flex2 temp04_margin">
											<h2 className="temp02_font_title medium">
												触れずに温度を測る 非接触型温度計。
											</h2>

											<p className="medium">
												すべての物体はその温度に応じて赤外線を放射しています。この性質を利用し、物体に触れず、離れた位置から赤外線をとらえ、温度を測るのが非接触型の温度計です。
											</p>

											<p className="medium">
												これは通常、放射温度計と呼ばれています。
											</p>

											<p className="medium">
												測定物体が遠く離れた場所にあっても、動いていても、危険で近づけない場所でも測定物体に影響を与えることなく瞬時に計測できるなど、これまでの接触型温度計とは異なる数多くのメリットをもっています。
											</p>

										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<PagingController current={4} next={'/support/documents/about-temperature/temp05'} prev={'/support/documents/about-temperature/temp03'} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
